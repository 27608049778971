<template>
	<v-container class="places">
    <v-btn color="primary" dark elevation="2" fab fixed bottom right @click="savePlaceShow('')">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-row>
			<v-col cols="12" class="mt-3 mb-3">
        <h2 class="text-h4">Населені пункти</h2>
      </v-col>
      <v-col cols="12">
        <v-sheet outlined rounded class="places_table_wrapper">
          <v-data-table :headers="tableHeaders" :items="places"
                        hide-default-footer disable-filtering
                        no-data-text="На жаль, немає жодного населеного пункта" :items-per-page="-1">
            <template v-slot:item.actions="{ item }">
              <v-btn small icon @click.stop="savePlaceShow(item)" class="mr-2">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn small icon @click.stop="deletePlaceConfirm(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <AppPageLoader :visible="isPageLoading" />
    <v-dialog v-model="isConfirmDeletePlaceVisible" max-width="500px">
      <v-card :loading="isPlaceBeingDeleted">
        <v-card-title class="pt-5 pb-5">Видалити цей населений пункт?</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDeletePlace">Відмінити</v-btn>
          <v-btn color="red" text @click="deletePlace">Видалити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isPlaceFormVisible" max-width="500px">
      <v-card :loading="isPlaceBeingSaved">
        <v-card-title class="pt-5 pb-5">{{ placeToEdit ? 'Змінити' : 'Новий' }} населений пункт</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="savePlace">
            <v-text-field v-model="placeName" label="Назва" outlined hide-details="auto" autofocus
                          @focus="$event.target.select()"
                          :error-messages="placeNameErrors" class="is-required" />
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeSavePlace">Відмінити</v-btn>
          <v-btn color="primary" text @click="savePlace">{{ placeToEdit ? 'Зберегти' : 'Додати' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="5000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snack = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { GET_PLACES, ADD_PLACE, EDIT_PLACE, DELETE_PLACE } from '@/store/places/types';

export default {
  data: () => ({
    placeName: '',
    placeToDelete: '',
    isPlaceBeingDeleted: false,
    isConfirmDeletePlaceVisible: false,
    isPlaceBeingSaved: false,
    isPlaceFormVisible: false,
    placeToEdit: '',
    isPageLoading: false,
    tableHeaders: [
			{
				text: 'Назва',
				value: 'name',
			},
			{
				text: 'Дія',
				value: 'actions',
        sortable: false,
        width: 100,
			},
    ],
    snack: false,
    snackColor: '',
    snackText: '',
  }),
  methods: {
    async init() {
      this.isPageLoading = true;
      await this.getPlaces();

      setTimeout(() => {
        this.isPageLoading = false;
      }, 500);
    },
    async getPlaces() {
      await this.$store.dispatch(GET_PLACES);
		},
    async deletePlace() {
			this.isPlaceBeingDeleted = true;
			await this.$store.dispatch(DELETE_PLACE, this.placeToDelete.id);
			await this.getPlaces();
			this.closeDeletePlace();
		},
		closeDeletePlace() {
			this.isPlaceBeingDeleted = false;
			this.placeToDelete = null;
			this.isConfirmDeletePlaceVisible = false;
		},
		deletePlaceConfirm(place) {
			this.placeToDelete = place;
			this.isConfirmDeletePlaceVisible = true;
		},
    async savePlace() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

			this.isPlaceBeingSaved = true;

      const place = {
        name: this.placeName,
      };

      try {

        if (this.placeToEdit) {
          place.id = this.placeToEdit.id;
          await this.$store.dispatch(EDIT_PLACE, place);
        } else {
          await this.$store.dispatch(ADD_PLACE, place);
        }
        await this.getPlaces();

      } catch (e) {
        this.snack = true;
        this.snackColor = 'error';
        this.snackText = 'Упс, щось пішло не так!';
      } finally {
        this.closeSavePlace();
      }
		},
		closeSavePlace() {
      this.placeName = '';
      this.placeToEdit = '';
			this.isPlaceBeingSaved = false;
			this.isPlaceFormVisible = false;
		},
		savePlaceShow(place) {
      this.$v.$reset();
      this.placeToEdit = place;
      this.placeName = place.name;
			this.isPlaceFormVisible = true;
		},
  },
  computed: {
    places() {
      return this.$store.getters.places;
    },
    placeNameErrors () {
      const errors = [];
      if (!this.$v.placeName.$dirty) return errors;
      !this.$v.placeName.required && errors.push('Назва обов\'язкова.');
      return errors;
    },
  },
  created() {
    this.init();
	},
  validations: {
    placeName: { 
      required,
    },
  },
}
</script>

<style lang="scss" scoped>
.places {
  max-width: 600px;
}
.places_table_wrapper {
	margin-bottom: 72px;
}
</style>
